import work1 from "../../../assets/images/portfolio/work-1.jpg";
import work2 from "../../../assets/images/portfolio/work-2.jpg";
import work3 from "../../../assets/images/portfolio/work-3.jpg";
import work4 from "../../../assets/images/portfolio/work-4.jpg";
import work5 from "../../../assets/images/portfolio/work-5.jpg";
import work6 from "../../../assets/images/portfolio/work-6.jpg";
import work7 from "../../../assets/images/portfolio/work-7.jpg";
import work8 from "../../../assets/images/portfolio/work-8.jpg";

import marioRossignoli from "../../../assets/images/client/mario-rossignoli.jpeg";
import francescaBianchi from "../../../assets/images/client/francesca-bianchi.jpeg";
import luigiMancuso from "../../../assets/images/client/luigi-mancuso.jpeg";
import elenaRiva from "../../../assets/images/client/elena-riva.jpeg";
import giorgioColombo from "../../../assets/images/client/giorgio-colombo.jpeg";
import silviaMoretti from "../../../assets/images/client/silvia-moretti.jpeg";
import paoloFerri from "../../../assets/images/client/paolo-ferri.jpeg";
import giuliaConti from "../../../assets/images/client/giulia-conti.jpeg";
import andreaDeLuca from "../../../assets/images/client/andrea-de-luca.jpeg";
import saraLombardi from "../../../assets/images/client/sara-lombardi.jpeg";

const portfolioData = [
  {
    id: 1,
    imageUrl: work1,
    title: "Centerd Gallary",
    category: ["profile", "business"],
    client: "Kay Garland",
  },
  {
    id: 2,
    imageUrl: work8,
    title: "Sidebar Stack",
    category: ["business", "finance"],
    client: "Kay Garland",
  },
  {
    id: 3,
    imageUrl: work3,
    title: "Grid – Overlay",
    category: ["profile", "business"],
    client: "Kay Garland",
  },
  {
    id: 4,
    imageUrl: work2,
    title: "Distinctive",
    category: ["marketing"],
    client: "Kay Garland",
  },
  {
    id: 5,
    imageUrl: work6,
    title: "Sustainable",
    category: ["finance", "marketing"],
    client: "Kay Garland",
  },
  {
    id: 6,
    imageUrl: work5,
    title: "Popup Hover",
    category: ["profile", "marketing"],
    client: "Kay Garland",
  },
  {
    id: 7,
    imageUrl: work7,
    title: "Vendor",
    category: ["business"],
    client: "Kay Garland",
  },
  {
    id: 8,
    imageUrl: work4,
    title: "Slider Cover",
    category: ["marketing"],
    client: "Kay Garland",
  },
];

const testimonialData = [
  {
    id: 1,
    review:
      "Da quando ho iniziato a usare il servizio di Neurapharm per la mia farmacia, i clienti possono ordinare farmaci e prenotare appuntamenti in modo facilissimo tramite WhatsApp. Il mio lavoro è diventato molto più semplice!",
    image: marioRossignoli,
    designation: "Titolare Farmacia",
    name: "Mario Rossignoli",
  },
  {
    id: 2,
    review:
      "L'integrazione con WhatsApp ha migliorato la gestione della mia farmacia. I clienti prenotano appuntamenti e fanno ordini senza intoppi, e tutto è gestito automaticamente dall'AI. Un vero cambiamento!",
    image: francescaBianchi,
    designation: "Farmacista",
    name: "Francesca Bianchi",
  },
  {
    id: 3,
    review:
      "Il servizio di Neurapharm ha reso la mia farmacia molto più efficiente. I clienti ricevono risposte immediate su orari, prodotti disponibili e prescrizioni, senza che io debba fare nulla. Eccezionale!",
    image: luigiMancuso,
    designation: "Responsabile Farmacia",
    name: "Luigi Mancuso",
  },
  {
    id: 4,
    review:
      "I miei clienti adorano la facilità con cui possono prenotare appuntamenti o ordinare farmaci tramite WhatsApp. Grazie a Neurapharm, posso concentrarmi su altre attività mentre l'AI gestisce le richieste.",
    image: elenaRiva,
    designation: "Farmacista",
    name: "Elena Riva",
  },
  {
    id: 5,
    review:
      "Finalmente un sistema che automatizza la gestione delle prenotazioni! Neurapharm ha semplificato ogni aspetto della mia farmacia, dalle prenotazioni di appuntamenti ai pagamenti online.",
    image: giorgioColombo,
    designation: "Proprietario Farmacia",
    name: "Giorgio Colombo",
  },
  {
    id: 6,
    review:
      "L'automazione di Neurapharm ha fatto risparmiare un sacco di tempo ai miei collaboratori. Non devono più rispondere alle domande frequenti dei clienti, tutto avviene automaticamente!",
    image: silviaMoretti,
    designation: "Gestore Farmacia",
    name: "Silvia Moretti",
  },
  {
    id: 7,
    review:
      "Con Neurapharm, le richieste di farmaci via WhatsApp vengono gestite in tempo reale e i clienti ricevono conferme immediate. Il servizio ha davvero migliorato la mia farmacia!",
    image: paoloFerri,
    designation: "Farmacista",
    name: "Paolo Ferri",
  },
  {
    id: 8,
    review:
      "L'AI di Neurapharm risponde automaticamente ai clienti su disponibilità dei prodotti e orari di apertura. Non devo più preoccuparmi di rispondere al telefono ogni minuto!",
    image: giuliaConti,
    designation: "Farmacista",
    name: "Giulia Conti",
  },
  {
    id: 9,
    review:
      "Il sistema di Neurapharm è facile da usare e ha reso più fluida la gestione della mia farmacia. Gli ordini arrivano direttamente via WhatsApp, e i clienti possono anche pagare in modo sicuro.",
    image: andreaDeLuca,
    designation: "Titolare Farmacia",
    name: "Andrea De Luca",
  },
  {
    id: 10,
    review:
      "Grazie a Neurapharm, posso offrire ai miei clienti un servizio di prenotazione e noleggio di dispositivi medici totalmente automatizzato. L'AI gestisce tutto e io posso concentrarmi su altre attività.",
    image: saraLombardi,
    designation: "Farmacista",
    name: "Sara Lombardi",
  },
];

export { portfolioData, testimonialData };
