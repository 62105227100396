import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import logo from "../assets/images/logo.png";

const Footer = () => {
  return (
    <React.Fragment>
      <section
        className="footer bg-dark"
        style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
      >
        <Container>
          <Row style={{ alignItems: "center", justifyContent: "center" }}>
            <Col lg={12} className="text-center mb-4">
              <div
                className="text-uppercase text-white"
                style={{ marginBottom: 0 }}
              >
                <img src={logo} alt="" style={{ maxHeight: 20 }} />
              </div>
            </Col>
            <Col lg={12} className="text-center mb-2">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1rem",
                }}
              >
                <a
                  href="https://www.iubenda.com/privacy-policy/58245674"
                  target="_blank"
                  rel="noreferrer"
                  className="nav-link"
                >
                  Privacy Policy
                </a>
                <a
                  href="https://www.iubenda.com/privacy-policy/58245674/cookie-policy"
                  target="_blank"
                  rel="noreferrer"
                  className="nav-link"
                >
                  Cookie Policy
                </a>
              </div>
            </Col>
            <Col lg={12} className="text-center">
              <p className="mb-0">
                NeuraPharm.ai 2024 P.IVA: 04175730169 - Via Giuseppe Verdi 12
                24121 – Bergamo
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Footer;
