const pricingData = [
  {
    id: 1,
    type: "PRO",
    value: "€99",
    fee: "0,99€ cent. / conv.",
    features: [
      "La nostra Intelligenza Artificiale per il mondo pharma con conoscenzainfinita sui prodotti",
      "Supporto al cliente per la gestione delle prenotazioni di prodotti",
      "Integrazione con il magazzino della farmacia",
      "",
      "",
    ],
  },
  {
    id: 2,
    type: "MAX",
    value: "€149",
    fee: "0,99€ cent. / conv.",
    features: [
      //   "Tutto quello previsto nel piano Pro",
      "Integrazione con il tuo Calendario Google",
      "Gestione della prenotazione dei servizi",
      "Aggiornamento della configurazione della personalità dell'AI ogni due mesi",
      "2 Lingue",
    ],
  },
  {
    id: 3,
    type: "SCALE",
    value: "€199",
    fee: "0,99€ cent. / conv.",
    features: [
      //   "Tutto quello previsto nel piano Max",
      "Gestione dei pagamenti all'interno di WhatsApp",
      "Sblocca accesso prioritario al nostro supporto clienti",
      "Aggiornamento AI una volta al mese",
      "Accesso prioritario a nuove funzionalità",
      "4 Lingue",
    ],
  },
  {
    id: 3,
    type: "ENTERPRISE",
    value: "CUSTOM",
    fee: "0,99€ cent. / conv.",
    features: [
      //   "Tutto quello previsto nel piano Scale",
      "Lavora con un Customer Success Manager dedicato",
      "Priority Onboarding",
      "Dedicate training session",
      "Piano tariffario dedicato in base ai tuoi bisogni",
      "12 Lingue",
    ],
  },
];

export default pricingData;
