import React from "react";
import { Col, Container, Row } from "react-bootstrap";

//img
import iphone1 from "../../assets/images/home/home-product-1.webp";
import iphone2 from "../../assets/images/home/home-product-2.webp";
import iphone3 from "../../assets/images/home/home-product-3.webp";
import ColoredText from "../../components/ColoredText";

const images = [iphone1, iphone2, iphone3];

const Index5 = () => {
  return (
    <React.Fragment>
      <section className="home-prestion" id="home">
        <div className="home-center">
          <div className="home-desc-center">
            <Container>
              <Row className="justify-content-center">
                <Col lg={8} className="text-center" style={{ zIndex: 100 }}>
                  <h4
                    className="mt-5 pt-5 home-title text-center"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ColoredText text="Whatsapp Ai per la farmacia" />
                    Il canale preferito dei tuoi clienti
                  </h4>
                  <h5 className="mobile-margin-vertical">
                    Il futuro è adesso. Farmacisti come te, in tutta Italia,
                    stanno già beneficiando di questa tecnologia.
                  </h5>
                  <h4 className="mobile-margin-vertical">
                    <ColoredText text="Sarai il prossimo?" />
                  </h4>
                </Col>
              </Row>
            </Container>
            <Container style={{ maxWidth: "100%", marginTop: "-10rem" }}>
              <Row className="justify-content-center">
                <Col lg={12} className="text-center">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {images.map((img, index) => (
                      <img
                        src={img}
                        alt=""
                        className={`img-fluid center-block home-dashboard ${
                          index !== 1 && "hidden-mobile"
                        }`}
                        style={{
                          top: "7rem",
                          maxHeight: index === 1 ? 600 : 760,
                          marginRight: index === 1 ? "-4rem" : 0,
                          marginLeft: index === 1 ? "-4rem" : 0,
                        }}
                      />
                    ))}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Index5;
