import React from "react";
import Header from "./NavBar";
import Services from "../components/Services";
import Pricing from "../components/Pricing";
import Footer from "../components/Footer";
import Claim from "../components/Claim";
import Testimonial from "../components/Testimonial";
import BigCta from "../components/BigCta";
import Index1 from "../pages/index1";

const Layout = (props: any) => {
  return (
    <React.Fragment>
      <Header />
      {props.children || <Index1 />}
      <Claim />
      <Services isIndex={props.isIndex} />
      <Pricing />
      <Testimonial />
      <BigCta />
      <Footer />
    </React.Fragment>
  );
};

export default Layout;
