import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import ColoredText from "./ColoredText";

const BigCta = () => {
  return (
    <React.Fragment>
      <section
        className="section bg-cta"
        style={{ backgroundColor: "#f8f9fa" }}
      >
        <Container className="p-5">
          <Row className="justify-content-center">
            <Col lg={8}>
              <h2 className="text-center">
                Non aspettare,{" "}
                <ColoredText text="automatizza la tua farmacia oggi!" />
              </h2>
              <p className="cta-desc text-center mt-3">
                Ogni minuto conta: inizia subito a migliorare la gestione della
                tua farmacia con Neurapharm e offri ai tuoi clienti un servizio
                impeccabile.
              </p>
              <div className="text-center mt-4">
                <Link
                  to="https://meetings.hubspot.com/l-nozza/neurapharmaai"
                  target="_blank"
                  className="btn btn-custom"
                >
                  INIZIA ORA
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default BigCta;
