const featuresData = [
  {
    icon: "uil uil-file-bookmark-alt",
    title: "Automazione completa delle prenotazioni",
    description:
      "I tuoi clienti possono ordinare farmaci, prenotare appuntamenti o chiedere informazioni semplicemente inviando un messaggio WhatsApp.",
  },
  {
    icon: "uil uil-comment-alt-question",
    title: "Risposte immediate a domande frequenti",
    description:
      "L'AI gestisce richieste su orari di apertura, disponibilità di prodotti e prescrizioni mediche",
  },
  {
    icon: "uil uil-padlock",
    title: "Ordini e pagamenti sicuri",
    description:
      "L’integrazione con il tuo gestionale e Stripe consente ai clienti di acquistare direttamente via WhatsApp, migliorando l’esperienza d'acquisto e aumentando il fatturato.",
  },
];

export default featuresData;
