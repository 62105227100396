import React from "react";
import { Col, Container, Row, Carousel } from "react-bootstrap";
import { testimonialData } from "./comon/data";
import ColoredText from "./ColoredText";

const Testimonial = () => {
  return (
    <React.Fragment>
      <section className="section" id="section">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className=" text-center">
                <h2 className="title-heading">
                  Cosa dicono i nostri farmacisti
                </h2>
                <p className="title-desc text-muted mt-3">
                  <ColoredText text="Automazione e semplicità:" /> scopri come
                  Neurapharm sta rivoluzionando il lavoro quotidiano nelle
                  farmacie grazie a WhatsApp.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <Container style={{ maxWidth: "100%" }}>
          <Row className="justify-content-center mt-4">
            <Col lg={12}>
              <div className="testimonial-carousel">
                {testimonialData.map((testimonial) => (
                  <div className="testi-box elevate testimonial-item">
                    <div className="text-center mt-4">
                      <div className="testi-content">
                        <p className="user-review text-center mb-0">
                          {testimonial.review}
                        </p>
                      </div>
                      <div className="mt-4">
                        <img
                          src={testimonial.image}
                          alt=""
                          className="img-fluid rounded-circle testi-user mx-auto d-block"
                        />
                      </div>
                      <div className="img-post text-center">
                        <p className="testi-patients text-muted mb-1 mt-3">
                          {testimonial.designation}
                        </p>
                        <h3
                          className="testi-client-name"
                          style={{ fontWeight: 300 }}
                        >
                          {testimonial.name}
                        </h3>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Testimonial;
