import React, { useEffect, useState } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";

import logo from "../assets/images/logo.png";

const Header = () => {
  const [activeLink, setActiveLink] = useState<any>();
  const [isSticky, setIsSticky] = useState("");

  window.onscroll = function () {
    let scrollTop = document.documentElement.scrollTop;
    if (scrollTop > 50) {
      setIsSticky("nav-sticky");
    } else {
      setIsSticky("");
    }
  };

  useEffect(() => {
    const activation = (event: any) => {
      const target = event.target.getAttribute("href");
      const navitem = event.target.parentElement;
      const element = document.querySelector(target);

      if (element) {
        navitem.classList.add("active");
        setActiveLink(navitem);
        if (activeLink && activeLink !== navitem) {
          activeLink.classList.remove("active");
        }
      }
    };

    const defaultLink = document.querySelector(".navbar-nav li.active");
    if (defaultLink) {
      defaultLink?.classList.add("active");
      setActiveLink(defaultLink);
    }

    const links = document.querySelectorAll(".navbar-nav a");
    links.forEach((link) => {
      link.addEventListener("click", activation);
    });

    return () => {
      links.forEach((link) => {
        link.removeEventListener("click", activation);
      });
    };
  }, [activeLink]);

  return (
    <React.Fragment>
      <Navbar
        expand="lg"
        className={`fixed-top navbar-custom sticky ${isSticky}`}
      >
        <Container>
          <Navbar.Brand className="logo" href="/">
            <img src={logo} alt="" style={{ maxHeight: 20 }} />
          </Navbar.Brand>
          <Nav
            as="ul"
            className="ms-auto mb-lg-0"
            style={{ alignItems: "center" }}
          >
            <li className="nav-item active hidden-mobile">
              <Nav.Link href="#home">Home</Nav.Link>
            </li>
            <li className="nav-item hidden-mobile">
              <Nav.Link href="#service">Servizi</Nav.Link>
            </li>
            <li className="nav-item hidden-mobile">
              <Nav.Link href="#price">Prezzo</Nav.Link>
            </li>
            <li className="nav-item hidden-mobile">
              <Nav.Link href="#section">Dicono di noi</Nav.Link>
            </li>
            <a
              target="_blank"
              rel="noreferrer"
              className="btn btn-custom"
              href="https://meetings.hubspot.com/l-nozza/neurapharmaai"
              style={{ padding: "8px 16px", borderRadius: 32 }}
            >
              Provalo ora
            </a>
          </Nav>
        </Container>
      </Navbar>
    </React.Fragment>
  );
};

export default Header;
