import React from "react";
import { featuresData } from "./comon/data";
import { Col, Container, Row } from "react-bootstrap";
import ColoredText from "./ColoredText";

import iphone1 from "../assets/images/home/home-product-4.webp";
import iphone2 from "../assets/images/home/home-product-2.webp";
import iphone3 from "../assets/images/home/home-product-5.webp";
import whatsapp1 from "../assets/images/services/whatsapp-bubble-1.webp";
import whatsapp2 from "../assets/images/services/whatsapp-bubble-2.webp";
import whatsapp3 from "../assets/images/services/whatsapp-bubble-3.webp";
import whatsapp4 from "../assets/images/services/whatsapp-bubble-4.webp";
import orderGmail from "../assets/images/services/order-gmail.webp";
import orderWhatsapp from "../assets/images/services/order-whatsapp.png";

const Services = ({ isIndex }: any) => {
  return (
    <React.Fragment>
      <section className={`section ${isIndex ? "mt-5" : ""}`} id="service">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="text-center">
                <h2 className="title-heading">
                  <ColoredText text="Liberati dalle attività ripetitive." />
                  <br />
                  Focalizzati su ciò che conta davvero.
                </h2>
              </div>
            </Col>
          </Row>

          <Row className="pt-5">
            {(featuresData || [])
              .slice(0, 3)
              .map((feature: any, index: number) => (
                <Col lg={4} key={index}>
                  <div className="features-box mt-4">
                    <div className="d-flex">
                      <div className="features-icon text-center flex-shrink-0">
                        <i className={feature.icon}></i>
                      </div>
                      <div className="flex-grow-1 ms-4">
                        <h3 className="mt-0 f-19">{feature.title}</h3>
                        <p className="text-muted">{feature.description}</p>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
          </Row>

          <Row className="pt-5">
            <div className="text-center mt-5 mb-5">
              <h2 className="title-heading">
                Perchè questo strumento è essenziale per la tua farmacia
              </h2>
            </div>
            <Col lg={12}>
              <Row
                className="justify-content-center mt-4"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Col lg={5}>
                  <h3>
                    Gestisci le richieste di farmaci in modo{" "}
                    <ColoredText text="automatico" />
                  </h3>
                  <p>
                    Quando un cliente invia una richiesta di farmaci tramite
                    WhatsApp, l'AI integrata con il tuo gestionale verifica in
                    tempo reale la disponibilità del prodotto. Il cliente riceve
                    una conferma immediata, senza bisogno del tuo intervento.
                  </p>
                </Col>
                <Col lg={7}>
                  <img
                    src={iphone1}
                    alt=""
                    className="img-fluid center-block"
                    style={{
                      maxHeight: 720,
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={12}>
              <Row
                className="justify-content-center mt-4 mobile-reverse"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Col lg={7}>
                  <div className="whatsapp-bubble-container">
                    {[whatsapp1, whatsapp2, whatsapp3, whatsapp4].map(
                      (img, index) => (
                        <img
                          src={img}
                          alt=""
                          className="img-fluid center-block zoom-in"
                          style={{
                            maxHeight: 200,
                            maxWidth: 320,
                            alignSelf:
                              index % 2 === 1 ? "flex-start" : "flex-end",
                          }}
                        />
                      )
                    )}
                  </div>
                </Col>
                <Col lg={5} className="text-opposite">
                  <h3>
                    Appuntamenti senza stress, gestiti{" "}
                    <ColoredText text="automaticamente" />
                  </h3>
                  <p>
                    Il cliente prenota un appuntamento direttamente su WhatsApp,
                    e l'AI gestisce l'intero processo: dall'invio della
                    richiesta alla conferma dell’orario disponibile. Tutto viene
                    sincronizzato con il tuo calendario.
                  </p>
                </Col>
              </Row>
            </Col>
            <Col lg={12}>
              <Row
                className="justify-content-center mt-4 mobile-margin-top"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Col lg={5}>
                  <h3>
                    Rispondi in tempo reale,{" "}
                    <ColoredText text="senza interrompere" /> il tuo lavoro
                  </h3>
                  <p>
                    L’AI integrata con il gestionale della farmacia risponde
                    automaticamente a domande frequenti come disponibilità di
                    prodotti, orari di apertura e giacenze. Ogni richiesta viene
                    gestita in tempo reale, senza la necessità di intervento
                    umano.
                  </p>
                </Col>
                <Col lg={7}>
                  <img
                    src={iphone2}
                    alt=""
                    className="img-fluid center-block"
                    style={{
                      maxHeight: 720,
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={12}>
              <Row
                className="justify-content-center mt-4 mobile-reverse"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Col lg={7}>
                  <div className="whatsapp-bubble-container">
                    {[orderGmail, orderWhatsapp].map((img, index) => (
                      <img
                        src={img}
                        alt=""
                        className="img-fluid center-block zoom-in"
                        style={{
                          maxHeight: 200,
                          width: 400,
                          alignSelf:
                            index % 2 === 1 ? "flex-start" : "flex-end",
                        }}
                      />
                    ))}
                  </div>
                </Col>
                <Col lg={5} className="text-opposite">
                  <h3>
                    Appuntamenti senza stress, gestiti{" "}
                    <ColoredText text="automaticamente" />
                  </h3>
                  <p>
                    Il cliente prenota un appuntamento direttamente su WhatsApp,
                    e l'AI gestisce l'intero processo: dall'invio della
                    richiesta alla conferma dell’orario disponibile. Tutto viene
                    sincronizzato con il tuo calendario.
                  </p>
                </Col>
              </Row>
            </Col>
            <Col lg={12}>
              <Row
                className="justify-content-center mt-4 mobile-margin-top"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Col lg={5}>
                  <h3>
                    Noleggiare dispositivi medici{" "}
                    <ColoredText text="non è mai stato così facile" />
                  </h3>
                  <p>
                    I clienti possono noleggiare dispositivi medici o
                    attrezzature direttamente via WhatsApp, con l’AI che
                    gestisce disponibilità, prenotazioni e conferme, tutto in
                    modo automatico
                  </p>
                </Col>
                <Col lg={7}>
                  <img
                    src={iphone3}
                    alt=""
                    className="img-fluid center-block"
                    style={{
                      maxHeight: 720,
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Services;
