import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { pricingData } from "./comon/data";
import ColoredText from "./ColoredText";

const Pricing = () => {
  return (
    <React.Fragment>
      <section className="section bg-light" id="price">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="text-center">
                <h2 className="title-heading">
                  Risparmia il tuo tempo,{" "}
                  <ColoredText text="con il piano giusto" />
                </h2>
              </div>
            </Col>
          </Row>
          <Row className="mt-4" style={{ alignItems: "center" }}>
            {(pricingData || [])?.map((price: any, index: number) => (
              <Col lg={3} key={index}>
                <div
                  className={`price mt-4 p-2 pt-5 pb-5 ${
                    index === pricingData.length - 2 ? "bg-white" : "bg-white"
                  }`}
                  style={{
                    boxShadow:
                      index === pricingData.length - 2
                        ? "0px 0px 24px rgba(208, 122, 202, 0.5)"
                        : "",
                  }}
                >
                  <div className="item text-center">
                    <div className="type pb-3">
                      <h3>{price.type}</h3>
                    </div>
                    <div className="value">
                      <h4 style={{ fontSize: 46 }}>{price.value}</h4>
                      <h4>{price.fee}</h4>
                    </div>
                    <div className="feature" style={{ textAlign: "left" }}>
                      <ul
                        className={`${
                          index === pricingData.length - 2
                            ? "list-unstyled"
                            : "list-unstyled text-muted"
                        }`}
                      >
                        {price.features.map((feature: any, index: number) => (
                          <li key={index}>
                            <p className="mobile-text-center">{feature}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <a
                        href="https://meetings.hubspot.com/l-nozza/neurapharmaai"
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn-custom"
                      >
                        Provalo ora
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Pricing;
