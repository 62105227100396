import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import logo from "../assets/images/partner/logo-pharmacy-times.webp";

const Claim = () => {
  return (
    <React.Fragment>
      <section
        className="section"
        id="claim"
        style={{
          backgroundColor: "#f8f9fa",
        }}
      >
        <Container>
          <Row className="justify-content-center mt-4">
            <Col lg={8}>
              <div className="testi-box">
                <div className="text-center mt-4">
                  <div
                    className="testi-content"
                    style={{ backgroundColor: "white" }}
                  >
                    <p className="user-review text-center mb-0">
                      "Artificial intelligence is significantly transforming
                      pharmacy practice, with the potential to enhance patient
                      safety. AI system can help pharmacist predict medication
                      demand, identify drug interactions, and monitor patent
                      adherence to treatment plants, allowing for “ more
                      personalized and effective care."
                    </p>
                    <div className="mt-4">
                      <img
                        src={logo}
                        alt=""
                        className="img-fluid rounded-circle testi-user mx-auto d-block"
                        style={{ height: 64, maxWidth: "unset" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Claim;
